import server from "./server";

export const postUserLogin = async (data) => {
  const response = await server().post("/CmsManagment/Login", data);
  return response.data;
};

export const getCurrentUser = async (data) => {
  const response = await server().get("/CmsManagment/CMSProfile", data);
  return response.data;
};

export const forgetPasswordApi = async ({ data }) => {
  const response = await server().post("/auth/ForgetPassword", data);
  return response.data;
};

export const getUsersApi = async ({
  pageNumber,
  pageSize,
  SearchValue,
  CompanyName,
  IsDesJoiningDate,
  IsDesLastLogin,
  IsFreeTrial,
  IsExternal,
  IsVerified,
  IsActive
}) => {
  const queryParams = new URLSearchParams({
    PageNumber: pageNumber,
    pageSize: pageSize,
  });
  if (SearchValue) {
    queryParams.append("SearchValue", SearchValue);
  }
  if (CompanyName) {
    queryParams.append("CompanyName", CompanyName);
  }
  if (IsDesJoiningDate) {
    queryParams.append("IsDesJoiningDate", IsDesJoiningDate);
  }
  if (IsDesLastLogin) {
    queryParams.append("IsDesLastLogin", IsDesLastLogin);
  }
  if (IsFreeTrial) {
    queryParams.append("IsFreeTrial", IsFreeTrial);
  }
  if (IsExternal) {
    queryParams.append("IsExternal", IsExternal);
  }
  if (IsVerified) {
    queryParams.append("IsVerified", IsVerified);
  }
  if (IsActive) {
    queryParams.append("IsActive", IsActive);
  }

  const queryString = queryParams.toString();
  const response = await server().get(
    `/CmsManagment/GetAllUsers?${queryString}`
  );
  return response.data;
};

export const verifyUserApi = async ({ data }) => {
  const response = await server().put("/CmsManagment/VerifyUser", data);
  return response.data;
};

export const deleteUserApi = async ({ id }) => {
  const response = await server().delete(`/CmsManagment/DeleteUserById/${id}`);
  return response.data;
};

export const updateUserApi = async ({ data }) => {
  const response = await server().put("/CmsManagment/UpdateUser", data);
  return response.data;
};

export const getUserApi = async ({ id }) => {
  const response = await server().get(`/CmsManagment/GetUserById/${id}`);
  return response.data;
};

export const assignCompanyApi = async ({ data }) => {
  const response = await server().put("/CmsManagment/UpdateUserCompany", data);
  return response.data;
};

export const removeAssignedCompanyApi = async ({ data }) => {
  const response = await server().put(
    "/CmsManagment/RemoveUserEdimateCompany",
    data
  );
  return response.data;
};

export const getUserAuditHistoryApi = async ({
  id,
  AuditAction,
  PageNumber,
  PageSize,
}) => {
  const response = await server().get(
    `/CmsManagment/GetUserAuditHistory?userId=${id}&AuditAction=${AuditAction}&PageNumber=${PageNumber}&PageSize=${PageSize}`
  );
  return response.data;
};

export const getUserControlBoardApi = async ({ id, companyId }) => {
  const response = await server().get(
    `/CmsManagment/GetUserControlBoard?userId=${id}&companyId=${companyId}`
  );
  return response.data;
};

export const getAllModulesApi = async () => {
  const response = await server().get(`/GetAllModules`);
  return response.data;
};

export const updateUserModulesApi = async ({ data }) => {
  const response = await server().put("/UpdateUserModule", data);
  return response.data;
};

export const addUserModuleApi = async ({ data }) => {
  const response = await server().post("/AddUserModule", data);
  return response.data;
};

export const getUserModulesApi = async ({ useerId }) => {
  const response = await server().get(
    `/CmsManagment/GetUserModulesByUserId?useerId=${useerId}`
  );
  return response.data;
};

export const getWinRatePerQuarterApi = async ({ useerId }) => {
  const response = await server().get(
    `/CmsManagment/GetWinRatePerQuarter?useerId=${useerId}`
  );
  return response.data;
};

export const assignUserApi = async ({ data }) => {
  const response = await server().post(
    "/Collaborator/AssignOrUnAssignCollaboratorToUser",
    data
  );
  return response.data;
};

export const removeAssignedUserApi = async ({ data }) => {
  const response = await server().post(
    "/Collaborator/AssignOrUnAssignCollaboratorToUser",
    data
  );
  return response.data;
};

export const getHealthStatusApi = async () => {
  const response = await server().get("/CmsManagment/GetTableStats");
  return response.data;
};

export const getUserCollaboratorsApi = async ({ ParentId }) => {
  const response = await server().get(
    `/Collaborator/GetParentWithCollaborators?ParentId=${ParentId}`
  );
  return response.data;
};

export const addKeyWordsApi = async ({ data }) => {
  const response = await server().post("/CmsManagment/AddKeyWords", data);
  return response.data;
};
export const getKeyWordsApi = async ({ AccountId, CompanyId }) => {
  const response = await server().get(
    `/CmsManagment/GetKeyWords?AccountId=${AccountId}&CompanyId=${CompanyId}`
  );
  return response.data;
};
export const toggleTrialApi = async ({ accountId }) => {
  const response = await server().put(
    `/CmsManagment/ToggleTrial?accountId=${accountId}`
  );
  return response.data;
};
